<template>
  <ExpandedCard title="Tasks" :loading="loading_items">
    <template v-slot:default>
      <PersonalTask :with-title="false"></PersonalTask>
    </template>
  </ExpandedCard>
</template>

<script>
import ExpandedCard from '../components/ExpandedCard.vue'
import { fetch_services } from '@/services/fetch/fetch_services'
import PersonalTask from '@/modules/PersonalTasks/PersonalTasksV2.vue'
export default {
  mixins: [fetch_services],
  components: { ExpandedCard, PersonalTask },
  created() {
    // this.loadItems()
  },
  methods: {
    //
  }
}
</script>

<style lang="scss" scoped></style>
