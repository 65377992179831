<template>
  <v-card min-height="100vh" outlined class="rounded-10">
    <v-overlay absolute :opacity="0.5" :value="loading">
      <v-progress-circular color="primary" indeterminate size="100">
        <v-avatar tile size="50">
          <v-img
            :lazy-src="settings.loader"
            contain
            :src="settings.squareLogo"
          ></v-img>
        </v-avatar>
      </v-progress-circular>
    </v-overlay>
    <v-card-title class="px-7 py-5">
      <v-row dense align="center">
        <strong class="primary--text fs-30 font-weight-bold">
          {{ title | ucwords }}
        </strong>
        <slot name="header-left"></slot>
        <v-spacer />
        <slot name="header-right"></slot>
        <v-btn icon @click="close">
          <v-icon large color="primary">mdi-close</v-icon>
        </v-btn>
      </v-row>
      <slot name="header-bottom"></slot>
    </v-card-title>
    <v-card-text>
      <slot name="default">
        <Empty headline="No data yet"></Empty>
      </slot>
    </v-card-text>
    <slot name="footer"></slot>
  </v-card>
</template>

<script>
export default {
  name: 'ExpandedCard',
  props: {
    loading: { type: Boolean, default: true },
    title: { type: String, default: '' }
  },
  methods: {
    close() {
      this.$router.push({ name: 'default-content' }).catch(() => {})
    }
  }
}
</script>
<style lang="scss" scoped>
.invoice-status {
  background: #223a6b !important;
}
</style>
